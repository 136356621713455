import './../../styles/auth.scss';
import {BASE_IMAGES} from "../../../../../main/constants/BASE_IMAGES";
import LoginInfo from "../../mainComponents/LoginInfo";

function LoginPresenter(
    {
        email,
        password,
        errors,
        touched,
        passwordVisible,
        isSubmitButtonDisabled,
        onEmailChange,
        onPasswordChange,
        onTogglePasswordVisibility,
        onSubmit,
        onRegisterRedirect,
    }
) {
    return (
        <div className="auth">
            <div className="auth-image-container">
                <img src={BASE_IMAGES.loginImage} alt="Login illustration" className="auth-image" />
            </div>
            <div className="form-container">
                <h1 className="title">LOGIN</h1>
                <form className="form" onSubmit={onSubmit}>
                    <div className="form-inputs">
                        {/* Email Input */}
                        <div>
                            <input
                                className="auth-input"
                                placeholder="Email"
                                value={email}
                                autoComplete="email"
                                onChange={onEmailChange}
                                aria-label="Email"
                            />
                            {touched.email && errors.email && <p className="error">{errors.email}</p>}
                        </div>

                        {/* Password Input */}
                        <div className="password-field">
                            <input
                                className="auth-input"
                                placeholder="Password"
                                type={passwordVisible ? 'text' : 'password'}
                                autoComplete="current-password"
                                value={password}
                                onChange={onPasswordChange}
                                aria-label="Password"
                            />
                            <button
                                type="button"
                                className="password-toggle-icon"
                                onClick={onTogglePasswordVisibility}
                                aria-label={passwordVisible ? 'Hide password' : 'Show password'}
                            >
                                <img
                                    src={passwordVisible ? BASE_IMAGES.showPasswordIcon : BASE_IMAGES.hidePasswordIcon}
                                    alt={passwordVisible ? 'Hide password' : 'Show password'}
                                    className="eye-icon"
                                />
                            </button>
                            {touched.password && errors.password && <p className="error">{errors.password}</p>}
                        </div>
                    </div>

                    <button type="submit" className="auth-button" disabled={isSubmitButtonDisabled}>
                        LOGIN
                    </button>
                </form>

                <LoginInfo
                    buttonName="REGISTER"
                    info="Don't have an account?"
                    action={onRegisterRedirect}
                />
            </div>
        </div>
    );
}

export default LoginPresenter;
