export class LocalDatabase {

    static setToken(token) {
        window.localStorage.setItem("token", token)
    }

    static getToken() {
        return window.localStorage.getItem("token")
    }

    static signOut() {
        return window.localStorage.removeItem("token")
    }

}