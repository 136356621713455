import { useEffect, useState } from 'react';
import { useLoading } from '../../../../../main/providers/LoadingProvider';
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from '../../../../../api/services/auth/authenticateUser';
import { toast } from 'react-toastify';
import { BASE_ROUTERS } from '../../../../../main/constants/BASE_ROUTERS';
import { LocalDatabase } from '../../../../../api/database/LocalDatabase';
import LoginPresenter from "./LoginPresenter";

function LoginContainer() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [touched, setTouched] = useState({ email: false, password: false });
    const [passwordVisible, setPasswordVisible] = useState(false);

    const { showLoading, hideLoading } = useLoading();
    const navigate = useNavigate();

    const validateForm = () => {
        const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

        const emailError = !email ? 'Email is required' : !isValidEmail(email) ? 'Invalid email format' : '';
        const passwordError = !password ? 'Password is required' : '';

        setErrors({ email: emailError, password: passwordError });

        return !emailError && !passwordError;
    };

    useEffect(() => {
        if (touched.email || touched.password) {
            validateForm();
        }
    }, [email, password, touched]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setTouched((prev) => ({ ...prev, email: true }));
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setTouched((prev) => ({ ...prev, password: true }));
    };

    const togglePasswordVisibility = () => setPasswordVisible((prev) => !prev);

    const resetPasswordField = () => setPassword('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        showLoading();
        try {
            const data = await authenticateUser(email, password);

            if (!data || !data.success) {
                const errorMessage = data?.messages?.[0]?.value || 'Unknown error occurred';
                toast.error(errorMessage);
                return;
            }

            LocalDatabase.setToken(data.data.token);
            toast.success('Successfully logged in');
            navigate(BASE_ROUTERS.main);
        } catch (error) {
            toast.error('Failed to log in, please try again later');
        } finally {
            resetPasswordField();
            hideLoading();
        }
    };

    const handleRegisterRedirect = () => navigate(BASE_ROUTERS.register);

    return (
        <LoginPresenter
            email={email}
            password={password}
            errors={errors}
            touched={touched}
            passwordVisible={passwordVisible}
            isSubmitButtonDisabled={!email || !password || errors.email || errors.password}
            onEmailChange={handleEmailChange}
            onPasswordChange={handlePasswordChange}
            onTogglePasswordVisibility={togglePasswordVisibility}
            onSubmit={handleSubmit}
            onRegisterRedirect={handleRegisterRedirect}
        />
    );
}

export default LoginContainer;
