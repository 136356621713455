import './../../styles/auth.scss';
import {BASE_IMAGES} from "../../../../../main/constants/BASE_IMAGES";
import LoginInfo from "../../mainComponents/LoginInfo";

function RegisterPresenter(
    {
        formValues,
        errors,
        touched,
        visibility,
        onChange,
        onSubmit,
        toggleVisibility,
        isSubmitDisabled,
        redirectToLogin,
    }
) {
    return (
        <div className="auth">
            <div className="form-container">
                <h1 className="title">REGISTER</h1>
                <form className="form" onSubmit={onSubmit}>
                    <div className="form-inputs">
                        {["name", "email"].map((field) => (
                            <div key={field}>
                                <input
                                    className="auth-input"
                                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                    value={formValues[field]}
                                    onChange={onChange(field)}
                                />
                                {touched[field] && errors[field] && (
                                    <p className="error">{errors[field]}</p>
                                )}
                            </div>
                        ))}

                        {["password", "confirmPassword"].map((field) => (
                            <div className="password-field" key={field}>
                                <input
                                    className="auth-input"
                                    placeholder={field === "password" ? "Password" : "Confirm Password"}
                                    type={visibility[field] ? "text" : "password"}
                                    value={formValues[field]}
                                    onChange={onChange(field)}
                                />
                                <span className="password-toggle-icon" onClick={() => toggleVisibility(field)}>
                                    <img
                                        src={visibility[field] ? BASE_IMAGES.showPasswordIcon : BASE_IMAGES.hidePasswordIcon}
                                        className="eye-icon"
                                    />
                                </span>
                                {touched[field] && errors[field] && (
                                    <p className="error">{errors[field]}</p>
                                )}
                            </div>
                        ))}
                    </div>

                    <button type="submit" className="auth-button" disabled={isSubmitDisabled()}>
                        REGISTER
                    </button>
                </form>
                <LoginInfo buttonName="LOGIN" info="Have an account?" action={redirectToLogin} />
            </div>
            <div className="auth-image-container">
                <img src={BASE_IMAGES.registerImage} className="auth-image" />
            </div>
        </div>
    );
}

export default RegisterPresenter;
