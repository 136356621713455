import {BASE_ROUTERS} from "../../../../main/constants/BASE_ROUTERS";
import {NavLink} from "react-router-dom";

function LoginInfo({ info, buttonName, action, onForgotPassword }) {
    return (
        <div className={"sign-up-info"}>
            <NavLink to={BASE_ROUTERS.forgotPassword} className="forgot-password" onClick={onForgotPassword}>
                Forgot password?
            </NavLink>

            <div className="text-with-lines">
                <p>or</p>
            </div>

            <p className={"register-info"}>
                {info}
                <span className={"auth-text-button"} onClick={action}>{buttonName}</span>
            </p>
        </div>
    )
}

export default LoginInfo