import {BASE_URL} from "../../BASE_API";

export async function verifyEmailCode(email, code) {
    try {
        const response = await fetch(`${BASE_URL}/ValidateVerification`, {
            method: 'POST',
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify({
                email: email,
                code: code,
            }),
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error during verification validation:', error.message);
        throw error; // Rethrow the error to propagate it to submitAction
    }
}