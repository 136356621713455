import { motion } from "framer-motion";
import Login from "./views/login/LoginContainer";
import RegisterContainer from "./views/register/RegisterContainer";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

function Auth({ isLogin }) {
    return (
        <div className="auth-container">
            <Header shouldHideOnScroll={false}/>
            <motion.div
                key={isLogin ? "login" : "register"}
                initial={{ opacity: 0, x: isLogin ? -150 : 150 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: isLogin ? 150 : -150 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
            >
                {isLogin ? <Login /> : <RegisterContainer/>}
            </motion.div>
            <Footer isAccountButtonShown={false}/>
        </div>
    );
}

export default Auth;
