import {useLocation, useNavigate, useRoutes} from "react-router-dom";
import {lazy, Suspense, useEffect, useState} from "react";
import {BASE_ROUTERS} from "../../main/constants/BASE_ROUTERS";
import Language from "./Language";
import Loading from "../Loading";
import Auth from "../pages/auth/Auth";
import ConfirmCodeContainer from "../pages/auth/views/confirm-code/ConfirmCodeContainer";
import {getCurrentCountry} from "../../api/services/getCurrentCountry";
import ForgotPasswordContainer from "../pages/auth/views/forgot-password/ForgotPasswordContainer";

const HomePage = lazy(() => import("../pages/home/HomePage"));
const AboutUsPage = lazy(() => import("../pages/about-us/AboutUsPage"));
const ServicesPage = lazy(() => import("../pages/services/ServicesPage"));
const HowToOrderPage = lazy(() => import("../pages/how-to-order/HowToOrderPage"));
const CatalogPage = lazy(() => import("../pages/catalog/CatalogPage"));
const InvitationDashboard = lazy(() => import("../pages/invitations/InvitationDashboard"));
const ComingSoon = lazy(() => import("../pages/coming-soon/ComingSoon"));

function Router() {

    const location = useLocation()
    const navigate = useNavigate();
    const [languagePrefix, setLanguagePrefix] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCurrentCountry()
                const countryCode = data.country

                let prefix
                switch (countryCode) {
                    case 'RU':
                    case 'KZ':
                    case 'BY':
                    case 'UA':
                        prefix = 'ru'
                        break
                    case 'AM':
                        prefix = null
                        break
                    default:
                        prefix = 'en'
                }
                setLanguagePrefix(prefix)
            } catch (error) {
                console.error(error)
            }
        }

        const _ = fetchData()
    }, [])

    useEffect(() => {
        if (languagePrefix && !location.pathname.startsWith(`/${languagePrefix}`)) {
            const currentPathWithoutLang = location.pathname.replace(/^\/(en|ru)/, "");
            navigate(`/${languagePrefix}${currentPathWithoutLang}`);
        }

    }, [languagePrefix, location.pathname]);

    const languageRoutes = [languagePrefix, "en", "ru", ""].map(language => ({
        element: <Language/>,
        children: getRoutes(language)
    }))

    const routes = languageRoutes.reduce((allRoutes, languageRoute) => {
        allRoutes.push(languageRoute)
        let routes = allRoutes.concat(languageRoute.children)

        return routes
    }, [])

    function getRouteElements(prefix) {
        return [
            { path: `*`, element: <ComingSoon/> },
            { path: `${prefix}${BASE_ROUTERS.comingSoon}`, element: <ComingSoon/>},
            { path: `${prefix}${BASE_ROUTERS.main}`, element: <HomePage/>},
            { path: `${prefix}${BASE_ROUTERS.login}`, element: <Auth isLogin={true}/>},
            { path: `${prefix}${BASE_ROUTERS.register}`, element: <Auth isLogin={false}/>},
            { path: `${prefix}${BASE_ROUTERS.confirmCodeForRegister}`, element: <ConfirmCodeContainer isRegisterFlow={true}/>},
            { path: `${prefix}${BASE_ROUTERS.confirmCodeForResetPassword}`, element: <ConfirmCodeContainer isRegisterFlow={false}/>},
            { path: `${prefix}${BASE_ROUTERS.forgotPassword}`, element: <ForgotPasswordContainer isCreateNewPasswordFlow={false}/>},
            { path: `${prefix}${BASE_ROUTERS.newPassword}`, element: <ForgotPasswordContainer isCreateNewPasswordFlow={true}/>},
            { path: `${prefix}${BASE_ROUTERS.aboutUs}`, element: <AboutUsPage/>},
            { path: `${prefix}${BASE_ROUTERS.services}`, element: <ServicesPage/>},
            { path: `${prefix}${BASE_ROUTERS.howToOrder}`, element: <HowToOrderPage/>},
            { path: `${prefix}${BASE_ROUTERS.main}/:categoryType`, element: <CatalogPage/>},
            { path: `${prefix}${BASE_ROUTERS.main}/:categoryType/:id`, element: <InvitationDashboard/>}
        ]
    }

    function getRoutes(language) {
        const languagePrefix = `/${language}`

        return getRouteElements(languagePrefix)
    }

    return (
        <Suspense fallback={
            <Loading/>
        }>
            {useRoutes(routes)}
        </Suspense>
    );
}

export default Router
