import {BASE_URL} from "../../BASE_API";

export async function sendEmailVerificationCode(email) {
    try {
        const response = await fetch(`${BASE_URL}/SendVerificationCode/${email}`, {
            method: 'POST',
            headers: {
                'accept': 'text/plain',
            },
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error sending verification code:', error.message);
    }
}