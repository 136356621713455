import React from "react";
import {motion} from "framer-motion";
import {BrowserRouter} from "react-router-dom";
import Router from "../router/router";
import {ScreenSizeProvider} from "../../main/providers/ScreenSizeProvider";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {LoadingProvider} from "../../main/providers/LoadingProvider";
import Loading from "../Loading";

function App() {

    return (
        <ScreenSizeProvider>
            <LoadingProvider>
                <motion.section initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0, y: 20}}
                                transition={{duration: 1}}
                                className={"app"}>
                    <BrowserRouter
                        future={{
                            v7_startTransition: true,
                            v7_relativeSplatPath: true
                        }}
                        children={
                            <Router/>
                        }
                    />
                    <ToastContainer
                        position={"bottom-center"}
                        autoClose={4000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeButton={true}
                        draggable
                        pauseOnHover
                        className={"toast"}
                    />
                    <Loading/>
                </motion.section>
            </LoadingProvider>
        </ScreenSizeProvider>
    );
}

export default App;
