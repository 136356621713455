import {BASE_URL} from "../../../BASE_API";

export async function sendCode(email) {
    try {
        const response = await fetch(`${BASE_URL}/SendCode/${email}`, {
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json-patch+json',
            }
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return response.json()
    } catch (error) {
        console.error('Error during verification validation:', error.message);
        throw error;
    }
}