import useViewportHeight from "../main/hooks/useViewportHeight";
import './custom-components/loading/styles/loading.css'
import animationData from "../../src/main/lottie/hyoor_loading.json";
import { useLoading } from "../main/providers/LoadingProvider";
import Lottie from "lottie-react";

function Loading() {

    const { isLoading } = useLoading();
    const viewportHeight = useViewportHeight()

    if (!isLoading) return null;

    return (
        <>
            <section className={"loading"}>
                <Lottie animationData={animationData} className={"lottie"}/>
            </section>
            <style jsx={"true"}>{`
                .loading {
                  height: ${viewportHeight};
                }
            `}</style>
        </>
    )
}

export default Loading