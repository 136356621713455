import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RegisterPresenter from "./RegisterPresenter";
import {useLoading} from "../../../../../main/providers/LoadingProvider";
import {BASE_ROUTERS} from "../../../../../main/constants/BASE_ROUTERS";
import {sendVerificationCodeRequest} from "../../custom/handlers";

function RegisterContainer() {

    const [formValues, setFormValues] = useState({ name: "", email: "", password: "", confirmPassword: "" });
    const [errors, setErrors] = useState({ name: "", email: "", password: "", confirmPassword: "" });
    const [touched, setTouched] = useState({ name: false, email: false, password: false, confirmPassword: false });
    const [visibility, setVisibility] = useState({ password: false, confirmPassword: false });

    const { showLoading, hideLoading } = useLoading();
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.values(touched).some((field) => field)) {
            validateForm();
        }
    }, [formValues, touched]);

    const handleChange = (field) => (e) => {
        setFormValues({ ...formValues, [field]: e.target.value });
        setTouched({ ...touched, [field]: true });
    };

    const toggleVisibility = (field) => {
        setVisibility({ ...visibility, [field]: !visibility[field] });
    };

    const validateForm = () => {
        const { name, email, password, confirmPassword } = formValues;
        const errors = {};

        if (touched.name && !name) errors.name = "Name is required";
        if (touched.email) {
            if (!email) errors.email = "Email is required";
            else if (!/\S+@\S+\.\S+/.test(email)) errors.email = "Invalid email format";
        }
        if (touched.password && !password) errors.password = "Password is required";
        if (touched.confirmPassword) {
            if (!confirmPassword) errors.confirmPassword = "Confirm Password is required";
            else if (confirmPassword !== password) errors.confirmPassword = "Passwords do not match";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const isSubmitDisabled = () => {
        return (
            Object.values(formValues).some((value) => !value) ||
            Object.values(errors).some((error) => error.length)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            showLoading();
            await sendVerificationCodeRequest(
                formValues.email,
                () => {
                    toast.success("The code was sent to your email");
                    navigate(BASE_ROUTERS.confirmCodeForRegister, { state: formValues });
                },
                () => {
                    resetForm();
                    hideLoading();
                }
            );
        }
    };

    const resetForm = () => {
        setFormValues({ name: "", email: "", password: "", confirmPassword: "" });
        setTouched({ name: false, email: false, password: false, confirmPassword: false });
    };

    const redirectToLogin = () => {
        navigate(BASE_ROUTERS.login);
    };

    return (
        <RegisterPresenter
            formValues={formValues}
            errors={errors}
            touched={touched}
            visibility={visibility}
            onChange={handleChange}
            onSubmit={handleSubmit}
            toggleVisibility={toggleVisibility}
            isSubmitDisabled={isSubmitDisabled}
            redirectToLogin={redirectToLogin}
        />
    );
}

export default RegisterContainer;
