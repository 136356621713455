import Header from "../../../../header/Header";
import Footer from "../../../../footer/Footer";
import {BASE_IMAGES} from "../../../../../main/constants/BASE_IMAGES";

function ForgotPasswordPresenter(
    {
        isCreateNewPasswordFlow,
        email,
        password,
        confirmPassword,
        error,
        errors,
        touched,
        isSubmitButtonDisabled,
        handleEmailChange,
        handlePasswordChange,
        handleConfirmPasswordChange,
        handleSubmit,
    }
) {
    return (
        <>
            {isCreateNewPasswordFlow ? (
                <div className="auth">
                    <div className="form-container">
                        <h1 className="title">CREATE A NEW PASSWORD</h1>
                        <p className="confirmation-info">
                            To secure your account, choose a strong password you haven’t used before.
                        </p>
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="form-inputs">
                                <div>
                                    <input
                                        className="auth-input"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    {touched.password && errors.password && <p className="error">{errors.password}</p>}
                                </div>
                                <div>
                                    <input
                                        className="auth-input"
                                        placeholder="Confirm New Password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                    {touched.confirmPassword && errors.confirmPassword && (
                                        <p className="error">{errors.confirmPassword}</p>
                                    )}
                                </div>
                            </div>
                            <button type="submit" className="auth-button" disabled={isSubmitButtonDisabled}>
                                Submit
                            </button>
                        </form>
                    </div>
                    <div className="auth-image-container">
                        <img src={BASE_IMAGES.forgotPasswordImage} className="auth-image" />
                    </div>
                </div>
            ) : (
                <div className="auth">
                    <div className="form-container">
                        <h1 className="title">Forgot Password</h1>
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="form-inputs">
                                <div>
                                    <input
                                        className="auth-input"
                                        placeholder="Email"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    {touched.email && error && <p className="error">{error}</p>}
                                </div>
                            </div>
                            <p className="confirmation-info">
                                We’ll send a verification code to this email if it matches an existing account.
                            </p>
                            <button type="submit" className="auth-button" disabled={isSubmitButtonDisabled}>
                                Send Code
                            </button>
                        </form>
                    </div>
                    <div className="auth-image-container">
                        <img src={BASE_IMAGES.forgotPasswordImage} className="auth-image" />
                    </div>
                </div>
            )}
        </>
    );
}


export default ForgotPasswordPresenter;
