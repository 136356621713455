export const BASE_ROUTERS = {
    comingSoon: "/",
    main: "/stage",
    login: "/stage/login",
    register: "/stage/register",
    forgotPassword: "/stage/forgotPassword",
    newPassword: "/stage/set-password",
    confirmCodeForRegister: "/stage/confirm-code",
    confirmCodeForResetPassword: "/stage/forgotPassword/confirm-code",
    aboutUs: "/stage/about-us",
    services: "/stage/services",
    howToOrder: "/stage/how-to-order"
}