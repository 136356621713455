import { useState, useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {BASE_ROUTERS} from "../../../../../main/constants/BASE_ROUTERS";
import ForgotPasswordPresenter from "./ForgotPasswordPresenter";
import Header from "../../../../header/Header";
import Footer from "../../../../footer/Footer";
import {toast} from "react-toastify";
import {useLoading} from "../../../../../main/providers/LoadingProvider";
import {sendCode} from "../../../../../api/services/auth/reset-password/sendCode";
import {resetPassword} from "../../../../../api/services/auth/reset-password/resetPassword";

function ForgotPasswordContainer({ isCreateNewPasswordFlow }) {
    return (
        <>
            <Header shouldHideOnScroll={false} />
            <Content isCreateNewPasswordFlow={isCreateNewPasswordFlow}/>
            <Footer isAccountButtonShown={false} />
        </>
    )
}

function Content({ isCreateNewPasswordFlow }) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [errors, setErrors] = useState({ password: "", confirmPassword: "" });
    const [touched, setTouched] = useState({ email: false, password: false, confirmPassword: false });
    const location = useLocation();

    const { showLoading, hideLoading } = useLoading();
    const navigate = useNavigate();

    useEffect(() => {
        if (touched.email || touched.password || touched.confirmPassword) {
            validateForm();
        }
    }, [email, password, confirmPassword, touched]);

    const validateForm = () => {
        if (isCreateNewPasswordFlow) {
            let valid = true;
            let passwordError = "";
            let confirmPasswordError = "";

            if (!password) {
                passwordError = "Password is required";
                valid = false;
            }
            if (confirmPassword !== password) {
                confirmPasswordError = "Passwords do not match";
                valid = false;
            }
            setErrors({ password: passwordError, confirmPassword: confirmPasswordError });
            return valid;
        } else {
            if (!email) {
                setError("Email is required");
                return false;
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                setError("Invalid email format");
                return false;
            }
            setError("");
            return true;
        }
    };

    const isSubmitDisabled = () => {
        if (isCreateNewPasswordFlow) {
            return (
                !password || !confirmPassword || errors.password || errors.confirmPassword
            )
        } else {
            return (
                !email || error
            )
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setTouched((prev) => ({ ...prev, email: true }));
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setTouched((prev) => ({ ...prev, password: true }));
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setTouched((prev) => ({ ...prev, confirmPassword: true }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoading();

        if (isCreateNewPasswordFlow) {
            try {
                const data = await resetPassword(location.state.email, password);

                if (!data || !data.success) {
                    const errorMessage = data?.messages?.[0]?.value || 'Unknown error occurred';
                    toast.error(errorMessage);
                    return;
                }

                toast.success('Your password has successfully changed');
                navigate(BASE_ROUTERS.login);
            } catch (error) {
                toast.error('Failed to log in, please try again later');
            } finally {
                hideLoading();
            }
        } else {
            try {
                const data = await sendCode(email);

                if (!data || !data.success) {
                    const errorMessage = data?.messages?.[0]?.value || 'Unknown error occurred';
                    toast.error(errorMessage);
                    return;
                }

                toast.success('Code is successfully sent to your email.');
                navigate(BASE_ROUTERS.confirmCodeForResetPassword, { state: { email } });
                setEmail("");
            } catch (error) {
                toast.error('Failed to log in, please try again later');
            } finally {
                hideLoading();
            }
        }
    };

    return (
        <ForgotPasswordPresenter
            isCreateNewPasswordFlow={isCreateNewPasswordFlow}
            email={email}
            password={password}
            confirmPassword={confirmPassword}
            error={error}
            errors={errors}
            touched={touched}
            isSubmitButtonDisabled={isSubmitDisabled()}
            handleEmailChange={handleEmailChange}
            handlePasswordChange={handlePasswordChange}
            handleConfirmPasswordChange={handleConfirmPasswordChange}
            handleSubmit={handleSubmit}
        />
    );
}

export default ForgotPasswordContainer;
